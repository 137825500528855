














































































































import "@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss";
import {Component, Ref, Vue, Watch} from "vue-property-decorator";
import FilterList from "@/components/filter/FilterList.vue";
import SettingColumns from "@/components/SettingColumns.vue";
import SelectPageSize from "@/components/select-page-size/select-page-size.vue";
import {AgGridVue} from "ag-grid-vue";
import {FilterAttributeResult} from "@/models/attribute/FilterAttributeResult";
import {QueryLoadOption, SortingInfo} from "@/models/QueryLoadOptions";
import {SettingColumnResult, SettingColumnSelect} from "@/models/settingColumn/SettingColumnResult";
import {FilterStateResult} from "@/models/filter/filter-state-result";
import filter from "lodash/filter";
import _each from "lodash/each";
import NumbericCellEditor from "@/views/order/order-edit/cell-editor/NumbericCellEditor.vue";
import map from "lodash/map";
import CellReceiptStatus from "@/views/cash-book/receipt/cell-render/CellReceiptStatus.vue";
import {SearchResult} from "@/models/SearchResult";
import CustomerCellComponent from "@/components/ag-grid/CustomerCellComponent.vue";
import CellReceiptCertificateCode from "@/views/cash-book/receipt/cell-render/CellReceiptCertificateCode.vue";
import {GuaranteeService} from "@/views/guarantee/GuaranteeService";
import QuantityCellRenderer from "@/views/order/order-edit/cell-renderer/QuantityCellRenderer.vue";
import {AttributeType, DataType, LogicType, OperatorType} from "@/constants/Attribute";
import moment from "moment";
import GuaranteePrint from "@/views/guarantee/GuaranteePrint.vue";
import {GuaranteeDetail} from "@/views/guarantee/models/GuaranteeDetail";
import _find from "lodash/find";
import ExportOptions from "@/views/order/order-list/ExportOptions.vue";
import {ExportType} from '@/constants/Order';
import _cloneDeep from "lodash/cloneDeep";
import _filter from "lodash/filter";

@Component({
    name: "ReceiptListDetail",
    components: {
        FilterList,
        AgGridVue,
        SelectPageSize,
        SettingColumns,
        GuaranteePrint,
        ExportOptions
    },
})

export default class GuaranteeListDetail extends Vue {
    @Ref("agGridTable") agGridTable!: any;
    @Ref("filter") filter!: any;
    @Ref("selectPageSize") selectPageSize!: any;
    @Ref("settingColumnsRef") settingColumnsRef!: any;
    @Ref('exportOptions') exportOptions!: any;
    searchQuery = "";
    gridOptions: any = {};
    maxPageNumbers = 7;
    gridApi: any = null;
    defaultColDef = {
        sortable: false,
        editable: false,
        resizable: true,
        suppressMenu: true,
    };
    columnDefs: any = null;
    totalPages = 0;
    totalRows = 0;
    frameworkComponents: any = null;
    guarantees: GuaranteeDetail[] = [];
    currentPage = 1;
    selectedIds: any = [];
    attributeOptions: FilterAttributeResult[] = [];

    // togge sidebar
    wasSidebarOpen: any = null;

    //setting column
    defaultSettingColumns: any[] = [];
    settingColumnConfig: SettingColumnResult = new SettingColumnResult();

    // Search loadOption
    loadOptions: QueryLoadOption = new QueryLoadOption();
    attributeSearchByKeywords = ["code", "referenceCode", "orderCode", "productId"];
    selectFieldRequired: string[] = ["providerId", "productId", "responsibleId", "guaranteeId"];
    listCustomTab: any[] = [];
    listStatus = [];
    onRowSelected: any = null;

    constructor() {
        super();
        this.loadOptions.sort = [new SortingInfo("createTime")];
    }

    beforeMount() {
        this.listCustomTab = [
            // {id: 'customerReport', title: this.$t('Report'), filter: null},
            {
                id: "detail",
                title: "Danh sách phiếu",
            },
        ];

        this.frameworkComponents = {
            "cellReceiptStatus": CellReceiptStatus,
            "customerCellComponent": CustomerCellComponent,
            "cellReceiptCertificateCode": CellReceiptCertificateCode,
            "numbericCellEditor": NumbericCellEditor,
            "quantityCellRenderer": QuantityCellRenderer,
        };

        this.gridOptions = {
            localeText: {noRowsToShow: "Không tìm thấy phiếu yêu cầu."},
        };

        this.onRowSelected = (params: any) => {
            let data = params.data;

            if (params.node.selected) {
                this.selectedIds.push(data.id);
            } else {
                this.selectedIds = _filter(this.selectedIds, (item: any) => {
                    return item !== data.id;
                });
            }
        };

        this.initial();
    }

    mounted() {
        this.gridApi = this.gridOptions.api;
        this.gridOptions.suppressRowTransform = true;

        //this.gridApi.sizeColumnsToFit();
        /* =================================================================
          NOTE:
          Header is not aligned properly in RTL version of agGrid table.
          However, we given fix to this issue. If you want more robust solution please contact them at gitHub
        ================================================================= */
        if (this.$vs.rtl) {
            const header = this.agGridTable.$el.querySelector(".ag-header-container");
            header.style.left = "-" + String(Number(header.style.transform.slice(11, -3)) + 9) + "px";
        }

        this.wasSidebarOpen = this.$store.state.reduceButton;
        this.$store.commit("TOGGLE_REDUCE_BUTTON", true);
    }

    beforeDestroy() {
        if (!this.wasSidebarOpen) this.$store.commit("TOGGLE_REDUCE_BUTTON", false)
    }

    get paginationPageSize() {
        if (this.gridApi) return this.gridApi.paginationGetPageSize();
        else return 20;
    }

    get rowData() {
        return this.guarantees;
    }

    @Watch("currentPage")
    onCurrentPageChange(value: number, oldValue: number) {
        this.search(false);
    }

    initial() {
        /// Lấy danh mục
        new GuaranteeService().getListStatus().then((result: any) => {
            this.listStatus = result;
            _each(this.listStatus, (item: any) => {
                item.id = item.value;
            })

            if (this.listStatus) {
                this.renderAttributeOptions();
                this.getColumnDefaultApi();
                new GuaranteeService().getUserConfigDetail()
                    .then((result: SettingColumnSelect[]) => {
                        this.settingColumnConfig.columnConfigData = result;
                        this.settingColumnsRef.agGridRefresh(this.settingColumnConfig);

                        this.loadOptions.select = map(filter(this.settingColumnConfig.columnConfigData, (item: SettingColumnSelect) => {
                            return item.isSelected;
                        }), "field");

                        const filterState: FilterStateResult = this.$store.getters["filter/getFilter"](this.$route.fullPath);

                        if (!filterState || filterState.searchValue !== null) {
                            this.search();
                        }

                        if (this.gridApi) {
                            this.gridApi.refreshCells();
                        }
                    });
            }
        })
    }

    onSortChange(e: any) {
        const columnSort = e.api.getSortModel();
        if (columnSort && columnSort[0]) {
            this.loadOptions.sort = [new SortingInfo(columnSort[0].colId, columnSort[0].sort === "desc")];

            this.setFilterState(this.currentPage, this.paginationPageSize, this.loadOptions);
            this.search(false);
        }
    }

    onRowDragEnd(e: any) {
        //this.gridApi.resetRowHeights()
    }

    changePageSize(pageSize: number) {
        this.gridApi.paginationSetPageSize(pageSize);
        this.currentPage = 1;

        this.setFilterState(this.currentPage, pageSize, this.loadOptions);
        this.search();
    }

    beginSearch(page: number) {
        if (this.searchQuery == undefined || this.searchQuery.length < 2) {
            return;
        }
        this.search();
    }

    searchByFilter(filterQuery: string) {
        this.loadOptions.filterQuery = filterQuery;
        this.currentPage = 1;
        this.search();
    }

    getRowStyle(params: any) {
        if (params.node.rowPinned) {
            return {"font-weight": "bold"};
        }
    }

    // Setting clumn
    openSettingColumnModal() {
        this.settingColumnsRef.openSettingColumnModal();
    }

    // Cập nhật column
    settingColumnUpdated(config: SettingColumnResult) {
        this.settingColumnConfig = config;
        this.getFieldSelectFromResult();
        this.search();
    }

    // Show bộ lọc
    showModalFilter(value: FilterAttributeResult[]) {
        if (!value || value.length === 0) {
            this.renderAttributeOptions();
        }
    }

    selectDetail(value: any) {
        this.$router.push({name: "guaranteeList"});
    }

    openExportOptions(){
        this.exportOptions.open();
    }

    async onExportFile(exportType: ExportType){
        let loadOptions = _cloneDeep(this.loadOptions) as any;
        loadOptions.exportType = exportType;

        if (exportType === ExportType.Selected) {
            loadOptions.selectedIds = this.selectedIds;
        }

        this.openLoading(true);
        await new GuaranteeService().exportExcel(loadOptions);
        this.openLoading(false);
    }

    private openLoading(value: boolean) {
        if (value) {
            this.$vs.loading();
        } else {
            this.$vs.loading.close();
        }
    }

    // Tìm kiếm
    private search(isShowDetail = false) {
        this.openLoading(true);
        const filterState: FilterStateResult = this.$store.getters["filter/getFilter"](this.$route.fullPath);
        if (filterState) {
            this.loadOptions.filterQuery = filterState.loadOption.filterQuery;
            this.gridApi.paginationSetPageSize(filterState.pageSize);
        }

        this.loadOptions.skip = (this.currentPage - 1) * this.paginationPageSize;
        this.loadOptions.take = this.paginationPageSize;

        new GuaranteeService()
            .searchDetail(this.loadOptions)
            .then((result: SearchResult<GuaranteeDetail>) => {
                this.openLoading(false);
                let listData: any = [];
                if (isShowDetail) {
                    _each(result.items, (item: any) => {
                        if (item.details) {
                            _each(item.details, detail => {
                                listData.push(detail);
                            })
                        } else {
                            listData.push(item);
                        }
                    })

                    this.guarantees = listData;
                } else {
                    this.guarantees = result.items;
                }

                this.setFilterState(this.currentPage, this.paginationPageSize);
                this.totalRows = result.totalRows;
                setTimeout(() => {
                    this.setTotalPage(this.totalRows);
                }, 200)
            })
            .catch(() => this.openLoading(false))
            .finally(() => this.openLoading(false));
    }

    /// Set total Page
    private setTotalPage(totalRows: number) {
        this.totalRows = totalRows;
        if (totalRows == 0) {
            this.totalPages = 0;
        } else if (totalRows <= this.paginationPageSize) {
            this.totalPages = 1;
        } else {
            this.totalPages = Math.ceil((totalRows / this.paginationPageSize));
        }
    }

    /// Tạo các thuộc tính tìm kiếm
    private renderAttributeOptions() {
        this.attributeOptions = [
            new FilterAttributeResult("Quantity", "Số lượng sản phẩm", "", "",
                false, AttributeType.NumberInput, [], [], OperatorType.Equal,
                "", LogicType.And, DataType.Int),
            new FilterAttributeResult("Price", " Tiền sửa chữa", "", "",
                false, AttributeType.NumberInput, [], [], OperatorType.Equal,
                "", LogicType.And, DataType.Int),
            new FilterAttributeResult("ProviderId", "Nhà cung cấp", "", "",
                false, AttributeType.DropdownList, [], [], OperatorType.Equal,
                `${process.env.VUE_APP_CUSTOMER_URL}api/v1/customers/suggestions`, LogicType.And, DataType.String),
            new FilterAttributeResult("ResponsibleId", "Người phụ trách", "", "",
                false, AttributeType.DropdownList, [], [], OperatorType.Equal,
                `${process.env.VUE_APP_CORE_URL}api/v1/users/suggestions`, LogicType.And, DataType.String),
            new FilterAttributeResult("Status", "Trạng thái", "", "",
                false, AttributeType.DropdownList, [], this.listStatus, OperatorType.Equal,
                "", LogicType.And, DataType.String),
            new FilterAttributeResult("DeliveryDate", "Ngày dự kiến trả hàng", "", "",
                false, AttributeType.DateTime, [], [], OperatorType.Equal,
                "", LogicType.And, DataType.DateTime),
        ];
    }

    private getColumnDefaultApi() {
        this.columnDefs = [
            {
                headerName: '',
                field: 'selection',
                filter: false,
                checkboxSelection: true,
                headerCheckboxSelectionFilteredOnly: false,
                headerCheckboxSelection: true,
                width: 50,
                pinned: 'left',
                suppressSizeToFit: true,
                //pinned: 'left'
            },
            {
                headerName: "Mã đơn hàng",
                field: "orderCode",
                filter: false,
                sortable: false,
                width: 100,
                pinned: "left",
                suppressSizeToFit: true,
                editable: false,
            },
            {
                headerName: "Mã SP",
                field: "productId",
                filter: false,
                sortable: false,
                width: 100,
                pinned: "left",
                suppressSizeToFit: true,
                autoHeight: true,
            },
            {
                headerName: "Tên SP",
                field: "productName",
                filter: false,
                sortable: false,
                width: 300,
                pinned: "left",
            },
            {
                headerName: "Đơn vị tính",
                field: "unitName",
                filter: false,
                sortable: false,
                width: 70,
            },
            {
                headerName: "Số lượng",
                headerClass: "number-cell",
                field: "quantity",
                width: 100,
                cellClass: "text-right",
                cellRender: "numbericCellEditor",
            },
            {
                headerName: "Phí sửa chữa",
                headerClass: "number-cell",
                field: "price",
                width: 150,
                cellClass: "text-right",
                valueGetter: (params: any) => {
                    const data = params.data;
                    const totalPrice = data.quantity * data.price;
                    return this.number_format(totalPrice, 0, ",", ".");
                },
            },
            {
                headerName: "Thành tiền",
                field: "totalAmount",
                filter: false,
                width: 100,
                editable: false,
                headerClass: "number-cell",
                cellClass: "text-right",
                valueGetter: (params: any) => {
                    const data = params.data;
                    const totalPrice = data.quantity * data.price;
                    return this.number_format(totalPrice, 0, ",", ".");
                },
            },
            {
                headerName: "Tình trạng SP",
                field: "receiveStatus",
                filter: false,
                sortable: false,
                width: 200,
            },
            {
                headerName: "Trạng thái",
                field: "status",
                filter: false,
                resizable: true,
                width: 150,
                cellRenderer: (params: any) => {
                    const data = params.data;
                    if ((typeof data.status) === "string") {
                        return `<a class="con-edit-td cursor-pointer">${data.status}</a>`
                    } else {
                        const info = _find(this.listStatus, (item: any) => {
                            return item.id === data.status;
                        })
                        if (info) {
                            return info.name;
                        } else {
                            return "";
                        }
                    }
                },
            },
            {
                headerName: "Người phụ trách",
                field: "responsibleFullName",
                filter: false,
                // sortable: false,
                resizable: true,
                width: 150,
            },
            {
                headerName: "Dự kiến trả",
                field: "deliveryDate",
                filter: false,
                width: 200,
                cellRender: (params: any) => {
                    const value = params.value;
                    if (value) {
                        if (moment(value, "DD-MM-YYYY").isValid()) {
                            return value;
                        }
                        return moment(value).format("DD-MM-YYYY");
                    }
                    return "";
                },
            },
            {
                headerName: "Ghi chú",
                field: "note",
                filter: false,
                sortable: false,
                width: 200,
            },
            {
                headerName: "Nhà cung cấp",
                field: "providerName",
                filter: false,
                sortable: false,
                width: 200,
            },
        ];
    }

    private getFieldSelectFromResult() {
        const columnSelect = filter(this.settingColumnConfig.columnConfigData, (item: SettingColumnSelect) => {
            return item.isSelected;
        });

        const fieldSelect = map(columnSelect,
            (item: SettingColumnSelect) => {
                return item.field
            });

        this.loadOptions.select = this.selectFieldRequired.concat(fieldSelect).filter((idx: string) => {
            return idx !== "actions" && idx !== "selection";
        });

        if (!fieldSelect || fieldSelect.length < 5) {
            this.gridApi.sizeColumnsToFit();
        }
    }

    private setFilterState(currentPage: number, pageSize: number, loadOption?: QueryLoadOption) {
        let filterState: FilterStateResult = this.$store.getters["filter/getFilter"](this.$route.fullPath);
        if (!filterState) {
            filterState = new FilterStateResult();
        }

        filterState.pageSize = pageSize;
        filterState.currentPage = currentPage;
        filterState.loadOption = loadOption ? loadOption : filterState.loadOption;
        filterState.module = this.$route.fullPath;
        filterState.attributeOptions = this.attributeOptions;

        // Cập nhật lại filter state
        this.$store.dispatch("filter/setFilterState", filterState);
    }

    private number_format(number: number, decimals: number, dec_point: string, thousands_sep: string) {
        const n = !isFinite(+number) ? 0 : +number,
            prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
            sep = (typeof thousands_sep === "undefined") ? "," : thousands_sep,
            dec = (typeof dec_point === "undefined") ? "." : dec_point,
            toFixedFix = function (n: any, prec: any) {
                // Fix for IE parseFloat(0.55).toFixed(0) = 0;
                var k = Math.pow(10, prec);
                return Math.round(n * k) / k;
            },
            s = (prec ? toFixedFix(n, prec) : Math.round(n)).toString().split(".");
        if (s[0].length > 3) {
            s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
        }
        if ((s[1] || "").length < prec) {
            s[1] = s[1] || "";
            s[1] += new Array(prec - s[1].length + 1).join("0");
        }
        return s.join(dec);
    }
}
